*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 14px;
}

html, body {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

p{
	text-align:justify;
}
  
body.dark-mode {
	color: #f8f8f8;
	--color-text: #0e1c23;
	--color-link: #c8bae4;
	--color-bg : #018559;
    --color-link-hover: #F4EAE2;
    --color-menu: #F4EAE2;
    --color-menu-hover: #4d4341;
    --color-circle:#F4EAE2;
    --color-content: #4d4341;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: roc-grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: all 0.7s ease;
  }

  #toggle-mode{
	color:var(--color-menu);
	border-color: var(--color-menu);
	background-color: rgba(0,0,0,0);
  }
 

body {
	margin: 0;
	--color-text: #00e29e;
	--color-link: #c8bae4;
	--color-bg : #0e1c23;
    --color-link-hover: #F4EAE2;
    --color-menu: #00e29e;
    --color-menu-hover: #fff;
    --color-circle: #00e29e;
    --color-content: #00e29e;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: roc-grotesk, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: all 0.9s ease;
}

/* Splitting */
.splitting .whitespace {
	display: inline-block;
    white-space: pre;
}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 180px;
	height: 180px;
	margin: -90px 0 0 -90px;
	border-radius: 50%;
	opacity: 0.4;
	border: 2px solid var(--color-menu);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5,0.5,1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover {
	color: var(--color-link-hover);
	outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
}

.unbutton:focus {
	outline: none;
}

.hover-line {
	overflow: hidden;
	position: relative;
	display: inline-block;
}

.hover-line::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 94%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
}

.hover-line:hover::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
}

main {
	display: grid;
	place-items: center;
	grid-auto-rows: 100vh;
}

/* Grainy texture animation by Geoff Graham https://css-tricks.com/snippets/css/animated-grainy-texture/ */

main::before {
	animation: grain 8s steps(10) infinite;
	background-image: url(../img/noise.png);
	content: '';
	height: 300%;
	left: -50%;
	opacity: 0.6;
	position: fixed;
	top: -100%;
	width: 300%;
}

@keyframes grain {
  0%, 100% { transform:translate(0, 0); }
  10% { transform:translate(-5%, -10%); }
  20% { transform:translate(-15%, 5%); }
  30% { transform:translate(7%, -25%); }
  40% { transform:translate(-5%, 25%); }
  50% { transform:translate(-15%, 10%); }
  60% { transform:translate(15%, 0%); }
  70% { transform:translate(0%, 15%); }
  80% { transform:translate(3%, 35%); }
  90% { transform:translate(-10%, 10%); }
}


h1{
	font-size:1rem;
	font-weight:100;
}
.frame {
	padding: 3rem 5vw;
	text-align: center;
	font-weight: 500;
	text-transform: uppercase;
	position: fixed;	
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
}
.frame__title {
	font-size: inherit;
	margin: 0 0 1rem;
	font-weight: 500;
}

.frame_end {
	padding: 3rem 5vw;
	text-align: left;
	font-weight: 500;
	text-transform: uppercase;
	position: fixed;	
	z-index: 100;
	bottom: 0;
	right: 0;
	width: 100%;
}
.frame__title {
	font-size: inherit;
	margin: 0 0 1rem 0;
	font-weight: 500;
}


.frame__links a:not(:last-child) {
	margin-right: 1rem;
}

.circle {
	fill: none;
    stroke: var(--color-circle);
    stroke-width: 1px;
	will-change: transform, opacity;
    position: absolute;
    pointer-events: none;
    top: 50%;
    left: 50%;
    margin: -800px 0 0 -800px;
}

.menu {
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	place-items: center;
	position: relative;
}

.menu__item {
	cursor: pointer;
	color: var(--color-menu);
	line-height: 0.9;
	text-transform: uppercase;
	font-size: 7.5vw;
	font-weight: 600;
	will-change: transform;
	pointer-events: none;
	transform: translate3d(0,0,0);
}

.menu--open .menu__item {
	pointer-events: auto;
}

.menu__item:hover,
.menu__item--current {
	color: var(--color-menu-hover);
}

.menu__item .char {
	will-change: transform, opacity;
}

.menu__item:nth-child(even) {
	font-weight: 300;
	font-style: italic;
	font-family: heimat-display-14, sans-serif;
}

.menu__item:nth-child(2) {
	margin-left: -10vw;
}

.menu__item:nth-child(3) {
	margin-left: -16vw;
}

.menu__item:nth-child(4) {
	margin-left: 18vw;
}

.menu__item:nth-child(5) {
	margin-left: -10vw;
}

.stack {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	pointer-events: none;
	display: grid;
	place-items: center;
	align-content: center;
	justify-content: center;
	grid-gap: 1vh;
	opacity: 0;
}

.stack__img {
	width: calc(100vw / 7);
	will-change: transform;
}

.content-wrap {
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	place-items: center;
	width: 100%;
	overflow: hidden;
}

.js .content {
	opacity: 0;
	pointer-events: none;
}

.js .content--current {
	opacity: 1;
	pointer-events: auto;
}

.content {
	grid-area: 1 / 1 / 2 / 2;
	display: grid;
	place-items: center;
	height: 100vh;
}

.content__title {
	color: var(--color-content);
	font-size: 9vw;
	font-weight: 600;
	text-transform: uppercase;
	white-space: nowrap;
	grid-area: 1 / 1 / 2 / 2;
}

.content:nth-child(even) .content__title {
	font-weight: 300;
	font-style: italic;
	font-family: heimat-display-14, sans-serif;
}

.content__title .char {
	will-change: transform, opacity;
}

.content__text {
	max-width: 50ch;
	margin: 0 auto;
	position: relative;
	align-self: end;
	margin-bottom: 3rem;
	font-size: 1.25rem;
	grid-area: 1 / 1 / 2 / 2;
	will-change: opacity, transform;
}

.content__text::after {
	content: '';
	position: fixed;
	pointer-events: none;
	z-index: 500;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	background: linear-gradient(transparent 0%,transparent 70%,var(--color-bg) 100%);
}

.close {
	color: var(--color-menu);
	cursor: pointer;
	line-height: 1;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0.65rem;
	font-weight: 500;
	right: 1rem;
	z-index: 150;
	text-transform: uppercase;
}

.close:hover,
.close:focus {
	color: var(--color-menu-hover);
}

.content--current ~ .close {
	opacity: 1;
	pointer-events: auto;
}

.close__cross {
	font-size: 120%;
	margin-right: 0.25rem;
}

@media screen and (min-width: 53em) {
	.frame {
		text-align: left;
		display: grid;
		align-content: space-between;
		max-width: none;
		height: 100vh;
		padding: 0.5rem 1rem;
		align-items: start;
		pointer-events: none;
		grid-template-columns: 30% 40% 30%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links ...'
							'... ... ...'
							'author ... sponsor';
	}
	
	.frame__title {
		margin: 0;
		grid-area: title;
		pointer-events: visible;
	}
	.frame__links {
		grid-area: links;
		padding: 0;
		justify-self: center;
	}
	.frame__author {
		grid-area: author;
		align-self: end;
		justify-self: start;
	}
	.frame a,
	.frame button {
		pointer-events: auto;
	}
}
@media screen and (min-width: 53em) {
	.frame_end {
	text-align: left;
	display: grid;
	align-content: end;
	max-width: none;
	height: 100vh;
	padding: 0.5rem 1rem;
	align-items: start;
	pointer-events: none;
	grid-template-columns: 30% 40% 30%;
	grid-template-rows: auto auto auto;
	grid-template-areas: 'title links ...'
						'... ... ...'
						'author ... sponsor';
}
}
@media screen and (max-width: 53em) {
	.menu__item {
		
		line-height: 0.8;
		font-size: 10vw;
	}
	.frame {
		text-align: left;
		display: grid;
		align-content: space-between;
		max-width: none;
		height: 100vh;
		padding: 0.5rem 1rem;
		align-items: start;
		pointer-events: none;
		grid-template-columns: 30% 40% 30%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links ...'
							'... ... ...'
							'author ... sponsor';
	}
	p {
		font-size:16px;
		padding: 0.5rem 1rem 0.5rem 1rem;
		width:100%;
	}
	.stack {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		pointer-events: none;
		display: grid;
		place-items: center;
		align-content: center;
		justify-content: center;
		grid-gap: 1vh;
		opacity: 0;
	}
	
	.stack__img {
		width: calc(100vw / 7);
		will-change: transform;
	}
}
