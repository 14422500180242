.splitting.cells img { width: 100%; display: block; }

@supports ( display: grid ) {
  .splitting.cells {
    position: relative;
    overflow: hidden;
    background-size: cover;
    visibility: hidden;
  }

  .splitting .cell-grid {
    background: inherit;
    position: absolute;
    top: 0; 
    left: 0; 
    width: 100%; 
    height: 100%;
    display: grid;
    grid-template: repeat( var(--row-total), 1fr ) / repeat( var(--col-total), 1fr );
  }

  .splitting .cell {
    background: inherit;
    position: relative;
    overflow: hidden;
  }

  .splitting .cell-inner {
    background: inherit;
    position: absolute;
    visibility: visible;
    /* Size to fit the whole container size */
    width: calc(100% * var(--col-total));
    height: calc(100% * var(--row-total));
    /* Position properly */
    left: calc(-100% * var(--col-index));
    top: calc(-100% * var(--row-index));
  }

  /* Helper variables for advanced effects */
  .splitting .cell {
    --center-x: calc((var(--col-total) - 1) / 2);
    --center-y: calc((var(--row-total) - 1) / 2);

    /* Offset from center, positive & negative */
    --offset-x: calc(var(--col-index) - var(--center-x));
    --offset-y: calc(var(--row-index) - var(--center-y));

    /* Absolute distance from center, only positive */
    --distance-x: calc( (var(--offset-x) * var(--offset-x)) / var(--center-x) );

    /* Absolute distance from center, only positive */
    --distance-y: calc( (var(--offset-y) * var(--offset-y)) / var(--center-y) );
  }
}

